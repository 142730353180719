<template>
  <div class="page-tutors">
    <div class="feature bg-tutors">
      <h1 class="feature-lead py-5">JAZRO TUTORS</h1>
    </div>
    <template v-if="tutors.length > 0">
      <div class="feature-section feature-section-responsive bg-cement">
        <div class="container row g-3 mx-auto">
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-3 py-2 my-0"
            v-for="tutor in tutors"
            :key="tutor._id"
          >
            <div class="card h-100 p-3">
              <div class="tutor-image ratio ratio-1x1 mx-auto mb-3">
                <img
                  :src="Helper.formatMediaUrl(tutor.image)"
                  :alt="tutor.name"
                />
              </div>
              <div class="text-center">
                <h6>{{ tutor.name }}</h6>
                <p class="small mb-2 text-muted">
                  {{ tutor.qualification }}
                </p>
                <p class="small mb-4 text-muted">
                  {{ tutor.institution }}
                </p>
                <ul class="m-0 small text-start list-unstyled">
                  <li
                    v-for="(achievement, index) in tutor.achievement"
                    :key="`${tutor._id}-achievement${index}`"
                    class="mb-2"
                  >
                    &middot; {{ achievement }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <loading-spinner
      class="position-relative bg-light text-muted"
      style="min-height: calc(100vh - 61px)"
      v-else
    >
      Loading
    </loading-spinner>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "view-tutors",
  components: { LoadingSpinner },
  data() {
    return {
      tutors: [],
    };
  },
  methods: {
    async getTutors() {
      return this.API.get("tutors?_limit=-1&_sort=rating:DESC")
        .then((retVal) => {
          this.tutors = retVal.data.map((data) => {
            data.achievement = data.achievement.split("\n");

            return data;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.getTutors();
  },
};
</script>